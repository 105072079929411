@import 'https://fonts.googleapis.com/css?family=Oxygen:300,400,700';

@font-face {
    font-family: 'FaktPro';
    src: url('/assets/fonts/fakt_pro/FaktPro-Normal.ttf') format('truetype'),
        url('/assets/fonts/fakt_pro/FaktPro-Normal.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'FaktPro';
    src: url('/assets/fonts/fakt_pro/FaktPro-SemiBold.ttf') format('truetype'),
        url('/assets/fonts/fakt_pro/FaktPro-SemiBold.woff') format('woff');
    font-weight: 600;
}

// Applicant font family; ".correspondence-popup .mat-tab-group" it's for override font family in cke editor's text-area
.applicant-font,
.correspondence-popup .mat-tab-group,
.cke_wysiwyg_div {
    font-family: 'FaktPro', sans-serif;
}

body {
    font-family: 'Oxygen', sans-serif;
}
